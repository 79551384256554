import { call, put, takeLatest } from "redux-saga/effects";
import HotelsService from "../../services/HotelsService/index";
import actions from "../actions/actions";

let columnMapForReducer = {
  name: "HOTEL NAME",
  hotel_group_name: "HOTEL GROUP",
  email: "EMAIL",
  type_of_subscription: "SUBSCRIPTION TYPE",
  amount: "MONTHLY FEE(€)",
  commision_percentage: "COMMISSION%",
  country_name: "COUNTRY",
};

const fetchHotels = async (page, limit) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getHotels(page, limit);
    return response;
  } catch (err) {
    throw err;
  }
};

const fetchSortedHotels = async (page, limit, columnName, sortBy) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getSortedHotels(
      page,
      limit,
      columnName,
      sortBy
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const fetchSearchSortedHotels = async (
  keyword,
  page,
  limit,
  columnName,
  sortBy
) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getSearchSortedHotels(
      keyword,
      page,
      limit,
      columnName,
      sortBy
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const createHotels = async (data) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.createHotels(data);
    return response;
  } catch (err) {
    throw err;
  }
};
const fetchHotelDetails = async (id) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getHotelDetails(id);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateHotelDetails = async (hotelId, data) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.updateHotelDetails(hotelId, data);
    return response;
  } catch (err) {
    throw err;
  }
};

const updateHotelStatus = async (hotelId, status) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.updateHotelStatus(hotelId, status);
    return response;
  } catch (err) {
    throw err;
  }
};

const fetchPms = async (payload) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getPmsList(payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const addPmsFormData = async (payload) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.addPmsFormData(payload);
    return response;
  } catch (err) {
    throw err;
  }
};

const fetchPmsHotelList = async (pmsId, hotelId) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.getPmsHotelList(pmsId, hotelId);
    return response;
  } catch (error) {
    throw error;
  }
};

const assignPmsHotel = async (pmsId, hotelId, payload) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.assignPmsHotel(pmsId, hotelId, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const updatePmsIntegration = async (payload) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.updatePmsIntegration(payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const syncCategory = async (pmsId, hotelId) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.syncCategories(pmsId, hotelId);
    return response;
  } catch (error) {
    throw error;
  }
};

const syncRooms = async (pmsId, hotelId) => {
  try {
    const HotelsServe = new HotelsService();
    const response = await HotelsServe.syncRooms(pmsId, hotelId);
    return response;
  } catch (error) {
    throw error;
  }
};

function* dispatchGetHotels(action) {
  try {
    const response = yield call(fetchHotels, action.page, action.limit);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTELS_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
      yield put({
        type: actions.SET_HOTEL_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_HOTEL_SORT,
        sortBy: action.sortBy,
        sortName: action.columnName,
        sortColumnName: columnMapForReducer[action.columnName]
          ? columnMapForReducer[action.columnName]
          : "HOTEL NAME",
        sort: action.sortBy === "DESC" ? true : false,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetSortedHotels(action) {
  try {
    const response = yield call(
      fetchSortedHotels,
      action.page,
      action.limit,
      action.columnName,
      action.sortBy
    );
    if (response.data.success) {
      yield put({
        type: actions.CLEAR_HOTEL_BY_ID,
      });
      yield put({
        type: actions.RESET_HOTEL_GROUP_LIST,
      });
      yield put({
        type: actions.FETCH_HOTELS_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
      yield put({
        type: actions.SET_HOTEL_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_HOTEL_SORT,
        sortBy: action.sortBy,
        sortName: action.columnName,
        sortColumnName: columnMapForReducer[action.columnName]
          ? columnMapForReducer[action.columnName]
          : "HOTEL NAME",
        sort: action.sortBy === "DESC" ? true : false,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetSearchSortedHotels(action) {
  try {
    const response = yield call(
      fetchSearchSortedHotels,
      action.payload,
      action.page_no,
      action.limit,
      action.columnName,
      action.sortBy
    );
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTELS_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
      yield put({
        type: actions.SET_HOTEL_LIST_PAGE_NO,
        page: response?.data?.data.page,
      });
      yield put({
        type: actions.SET_HOTEL_SORT,
        sortBy: action.sortBy,
        sortName: action.columnName,
        sortColumnName: columnMapForReducer[action.columnName]
          ? columnMapForReducer[action.columnName]
          : "HOTEL NAME",
        sort: action.sortBy === "DESC" ? true : false,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchCreateHotel(action) {
  try {
    const response = yield call(createHotels, action.payload);

    if (response.data.success) {
      yield put({
        type: actions.ADD_HOTELS_SUCESS,
        data: response.data,
        msg: response.msg,
      });
    } else {
      yield put({
        type: actions.ADD_HOTELS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchGetHotelDetails(action) {
  try {
    const response = yield call(fetchHotelDetails, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.FETCH_HOTEL_DETAILS_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTEL_DETAILS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchupdateHotelDetails(action) {
  try {
    const response = yield call(
      updateHotelDetails,
      action.hotelId,
      action.payload
    );
    if (response?.data?.success) {
      yield put({
        type: actions.UPDATE_HOTEL_DETAILS_SUCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.UPDATE_HOTEL_DETAILS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response || error,
    });
  }
}

function* dispatchupdateHotelStatus(action) {
  try {
    const response = yield call(
      updateHotelStatus,
      action.hotelId,
      action.payload
    );
    if (response.data.success) {
      yield put({
        type: actions.UPDATE_HOTEL_STATUS_SUCESS,
        data: response.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.UPDATE_HOTEL_STATUS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchSearchHotel(action) {
  try {
    let response = yield call(
      searchKeywordHotel,
      action.keyword,
      action.page_no,
      action.limit
    );
    if (response.success) {
      yield put({
        type: actions.FETCH_HOTELS_SUCESS,
        data: response.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.FETCH_HOTELS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

const searchKeywordHotel = async (keyword, page_no, limit) => {
  let HotelsServe = new HotelsService();
  const response = await HotelsServe.getHotelSearchGroup(
    keyword,
    page_no,
    limit
  );
  return response.data;
};

function* dispatchResetUpdateHotel(action) {
  yield put({
    type: actions.FETCH_HOTEL_DETAILS_SUCESS,
    msg: "Successfully reset",
    data: {},
  });
}

function* dispatchGetPms(action) {
  try {
    let response = yield call(fetchPms, action.payload);
    if (response.data.success) {
      yield put({
        type: actions.GET_PMS_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_PMS_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_PMS_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchAddPmsFormData(action) {
  try {
    const response = yield call(addPmsFormData, action.payload);

    if (response.data.success) {
      yield put({
        type: actions.ADD_PMS_FORM_DATA_SUCESS,
        data: response.data?.data,
        msg: response.data?.msg,
      });
    } else {
      yield put({
        type: actions.ADD_PMS_FORM_DATA_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
    yield put({
      type: actions.ADD_PMS_FORM_DATA_FAILED,
      msg: error?.msg,
    });
  }
}

function* dispatchGetPmsHotels(action) {
  try {
    const response = yield call(
      fetchPmsHotelList,
      action.pmsId,
      action.hotelId
    );

    if (response.data.success) {
      yield put({
        type: actions.GET_PMS_HOTELS_BY_ID_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.GET_PMS_HOTELS_BY_ID_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.GET_PMS_HOTELS_BY_ID_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchAssignPmsHotel(action) {
  try {
    const response = yield call(
      assignPmsHotel,
      action.pmsId,
      action.hotelId,
      action.payload
    );

    if (response.data.success) {
      yield put({
        type: actions.ASSIGN_PMS_HOTEL_SUCCESS,
        data: response.data?.data,
        msg: response.data?.msg,
      });
    } else {
      yield put({
        type: actions.ASSIGN_PMS_HOTEL_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
    yield put({
      type: actions.ASSIGN_PMS_HOTEL_FAILED,
      msg: error?.msg,
    });
  }
}

function* dispatchUpdatePmsIntegration(action) {
  try {
    const response = yield call(updatePmsIntegration, action.payload);
    if (response.data.success) {
      
      yield put({
        type: actions.UPDATE_PMS_INTEGRATION_SUCCESS,
        msg: response.data.msg,
      });
      yield put({
        type: actions.GET_HOTEL_DETAILS,
        payload: action?.payload?.hotel_id
      })
      yield put({
        type: actions.API_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: actions.UPDATE_PMS_INTEGRATION_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_PMS_INTEGRATION_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchSyncCategory(action) {
  try {
    const response = yield call(syncCategory, action.pmsId, action.hotelId);

    if (response.data.success) {
      yield put({
        type: actions.SYNC_CATEGORIES_SUCCESS,
        data: response.data?.data,
        msg: response.data?.msg,
      });
    } else {
      yield put({
        type: actions.SYNC_CATEGORIES_FAILED,
        msg: response.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SYNC_CATEGORIES_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

function* dispatchSyncRooms(action) {
  try {
    const response = yield call(syncRooms, action.pmsId, action.hotelId);

    if (response.data.success) {
      yield put({
        type: actions.SYNC_ROOMS_SUCCESS,
        data: response.data.data,
        msg: response.data.msg,
      });
    } else {
      yield put({
        type: actions.SYNC_ROOMS_FAILED,
        msg: response.data.msg,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SYNC_ROOMS_FAILED,
      msg: error?.response?.data?.msg,
    });
    yield put({
      type: actions.API_FAILED,
      data: error?.response,
    });
  }
}

export function* listenGetHotels() {
  yield takeLatest(actions.GET_HOTELS, dispatchGetHotels);
}
export function* listenGetSortedHotels() {
  yield takeLatest(actions.GET_SORTED_HOTELS, dispatchGetSortedHotels);
}

export function* listenGetSearchSortedHotels() {
  yield takeLatest(actions.SEARCH_HOTEL_SORTED, dispatchGetSearchSortedHotels);
}

export function* listenCreateHotels() {
  yield takeLatest(actions.ADD_HOTELS, dispatchCreateHotel);
}
export function* listenGetHotelDetails() {
  yield takeLatest(actions.GET_HOTEL_DETAILS, dispatchGetHotelDetails);
}
export function* listenupdateHotelDetails() {
  yield takeLatest(actions.UPDATE_HOTEL_DETAILS, dispatchupdateHotelDetails);
}
export function* listenupdateHotelStatus() {
  yield takeLatest(actions.UPDATE_HOTEL_STATUS, dispatchupdateHotelStatus);
}

export function* searchHotel() {
  yield takeLatest(actions.SEARCH_HOTEL, dispatchSearchHotel);
}

export function* listenResetUpdateHote() {
  yield takeLatest(actions.RESET_UPDATE_HOTEL, dispatchResetUpdateHotel);
}

export function* listenGetPms() {
  yield takeLatest(actions.GET_PMS, dispatchGetPms);
}

export function* listenAddPmsFormData() {
  yield takeLatest(actions.ADD_PMS_FORM_DATA, dispatchAddPmsFormData);
}

export function* listenGetPmsHotel() {
  yield takeLatest(actions.GET_PMS_HOTELS_BY_ID, dispatchGetPmsHotels);
}

export function* listenAssignPmsHotel() {
  yield takeLatest(actions.ASSIGN_PMS_HOTEL, dispatchAssignPmsHotel);
}

export function* listenUpdatePmsIntegration() {
  yield takeLatest(
    actions.UPDATE_PMS_INTEGRATION,
    dispatchUpdatePmsIntegration
  );
}

export function* listenSyncCategory() {
  yield takeLatest(actions.SYNC_CATEGORIES, dispatchSyncCategory);
}

export function* listenSyncRooms() {
  yield takeLatest(actions.SYNC_ROOMS, dispatchSyncRooms);
}
