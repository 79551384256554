import axios from "../axiosInstance";
import pmsAxios from "../pmsInstance";

class HotelsService {
  getHotels = async (page_no, limit) => {
    try {
      const response = await axios.get(
        `/hotels?page=${page_no}&limit=${limit}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getSortedHotels = async (page_no, limit, columnName, sortBy) => {
    try {
      const response = await axios.get(
        `/hotels?page=${page_no}&limit=${limit}&sort=${columnName}&sortBy=${sortBy}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getSearchSortedHotels = async (
    keyword,
    page_no,
    limit,
    columnName,
    sortBy
  ) => {
    try {
      const response = await axios.get(
        `/hotels?searchKey=${keyword}&page=${page_no}&limit=${limit}&sort=${columnName}&sortBy=${sortBy}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  createHotels = async (data) => {
    try {
      const response = await axios.post("/hotel", data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getHotelDetails = async (id) => {
    try {
      const response = await axios.get(`/hotel/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateHotelStatus = async (hotelId, status) => {
    try {
      const response = await axios.put(`/hotel/${hotelId}/status`, status);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateHotelDetails = async (hotelId, data) => {
    try {
      const response = await axios.put(`/hotel/${hotelId}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getHotelSearchGroup = async (key, page_no, limit) => {
    try {
      const response = await axios.get(
        `/hotels?searchKey=${key}&page=${page_no}&limit=${limit}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getPmsList = async (payload) => {
    try {
      const response = await pmsAxios.get(`/pms?hotel_id=${payload}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  addPmsFormData = async (payload) => {
    try {
      const response = await pmsAxios.post("/pms", payload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getPmsHotelList = async (pmsId, hotelId) => {
    try {
      const response = await pmsAxios.get(`/pms/${pmsId}/hotels/${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  assignPmsHotel = async (pmsId, hotelId, payload) => {
    try {
      const response = await pmsAxios.post(
        `/pms/${pmsId}/hotel/${hotelId}`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  updatePmsIntegration = async (payload) => {
    try {
      const response = await pmsAxios.put(`/pms`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  syncCategories = async (pmsId, hotelId) => {
    try {
      const response = await pmsAxios.get(`/categories/${pmsId}/${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  syncRooms = async (pmsId, hotelId) => {
    try {
      const response = await pmsAxios.get(`/rooms/${pmsId}/${hotelId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default HotelsService;
